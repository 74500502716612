

$grey: #F5F6F8;
$blue: #0071BB;
$darken-blue: #173557;
$blue-text: #1593D5;
$lighten-blue: #169FDB;
$text-grey: #9B9B9B;
$dark-blue: #007EFC;
$black: #000;
$transparent: transparent;
$light-silver: #FAFAFA;
$darken-brown: #4A4A4A;
$light-green: #68C0B5;
$light-white: #B2B2B2;
$dark-green: #B8E986;
$dark-brown: #CACACA;
$light-blue: #E5F0F8;
$white: #fff;
$text: #7F7F7F;
$text-color: #B2B2B2;
$ccGrey: #ccc;
$dardk-brown: #D8D8D8;
$dark-grey: #eee;
$border-color: #ced4da;
$light-grey: #D4D4D4;
$black: #000;