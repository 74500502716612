/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 6, 2020 */



@font-face {
    font-family: 'airbnb_cereal_appblack';
    src: url('airbnbcerealblack-webfont.woff2') format('woff2'),
         url('airbnbcerealblack-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'airbnb_cereal_appbold';
    src: url('airbnbcerealbold-webfont.woff2') format('woff2'),
         url('airbnbcerealbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'airbnb_cereal_appbook';
    src: url('airbnbcerealbook-webfont.woff2') format('woff2'),
         url('airbnbcerealbook-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'airbnb_cereal_applight';
    src: url('airbnbcereallight-webfont.woff2') format('woff2'),
         url('airbnbcereallight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'airbnb_cereal_appmedium';
    src: url('airbnbcerealmedium-webfont.woff2') format('woff2'),
         url('airbnbcerealmedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'arlonsemibold_demo';
    src: url('arlonsemibold-demo-webfont.woff2') format('woff2'),
         url('arlonsemibold-demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'font_awesome_5_freesolid';
    src: url('fontawesome5free-solid-900-webfont.woff2') format('woff2'),
         url('fontawesome5free-solid-900-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('AvenirNextLTPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

