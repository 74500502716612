@import "../../../assets/styles/variables.scss";

// .heading-of-form{
//   font-size: 14px;
// }
// .col-md-6{
//   padding-left: 30px !important;
// }
// .col-md-6 , .col-md-12{
//   padding: .75rem;
//   vertical-align: top;
//   border-top: 1px solid #dee2e6;
// }

#productList{
  width: 250px !important;
}
.customQuoteNo {
  margin-right: 25px !important;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px !important;
  }
}
.tdClassForSelectIssue {
  overflow: inherit !important;
}
.css-1wa3eu0-placeholder{
  color: rgb(51, 51, 51)  !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
    max-width: 100% !important;
    overflow: hidden !important;
    position: absolute !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    box-sizing: border-box !important;
}
.custom-input {
  color: #666 !important;
  background-color: #fafafa !important;
  height: 45px !important;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08) !important;
  // width: 49%;
  display: inherit;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.is-invalid-input {
  border: 1px solid rgb(184, 0, 0) !important;
  box-shadow: inset 0px 1px 3px 0px rgba(184, 0, 0, 0.08) !important;
}
td {
  vertical-align: middle !important;
}
.btn {
  font-size: 1rem;
}
.add-row {
  padding: 0.2rem 1.75rem !important;
  font-size: 12px !important;
}
span.error {
  position: absolute;
  font-size: 0.5rem;
}
.quote-form {
  margin-top: 100px;

  h2 {
    font-size: 30px !important;
  }
  .text-small {
    font-size: 17px !important;
  }
  img {
    display: block;
    margin: auto;
    // width: 100%;
  }

  h3 {
    font-size: 32px;
    color: $blue;
    // margin: 0 -15px 1rem -15px;
    font-weight: 600;
  }

  h6 {
    font-size: 18px;
    color: $darken-blue;
    // margin: 0 -15px 1rem -15px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    // margin: 0 -15px 1.5rem -15px;
  }
  th {
    text-align: center;
  }
  .btn-primary {
    background-color: $blue;
    font-size: medium;
    &:hover {
      background-color: $blue-text;
    }
  }
  .btn {
    font-size: medium;
  }
}

.modal-dialog {
  max-width: 1000px;
  margin: 1.75rem auto;
  min-height: calc(100% - 3.5rem);
}
.modal-footer {
  padding: unset !important;
}
.plus-button {
  width: 45px !important;
  height: 38px !important;
  padding: unset !important;
}
.cancel-button {
    margin-right: 10px;
}
.modal-buttons {
  font-size: larger !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}
