@import "../../assets/styles/variables.scss";

  .login {
    margin-top: 100px;
    img {
      display: block;
      margin: auto;
      // width: 100%;
    }
    h3 {
      font-size: 32px;
      color: $blue;
      margin: 0 -15px 1rem -15px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      margin: 0 -15px 1.5rem -15px;
    }
    a {
      color: $blue;
      font-weight: 400;
      &:hover{
        color: $blue-text;
    }
    }
    
    .btn{
        background-color: $blue;
        &:hover{
            background-color: $blue-text;
        }
    }
    input:-webkit-autofill::first-line {
      font-family: 'Helvetica Neue', 'Arial', Arial, sans-serif !important;
      font-size: .6rem !important;
    }
  }
